import './../../assets/scss/page22.scss';

import FaqList from './components/FaqList';

import '@topgeschenken/giftcard-client/src/balance-checker'; // used in page templates

class Page {
    constructor() {
        Page.bindEvents();
    }

    static bindEvents() {
        for(const listElm of document.getElementsByClassName('faq-list')) {
            new FaqList(listElm);
        }
    }
}

$(function() {
    new Page();
});
