export default class FaqList {
    constructor(elm) {
        this.elm = elm;

        this.bindEvents();
    }

    bindEvents() {
        if(this.elm) {
            this.hideFaqListContentItems();
            this.addClickEventToHeadings();
            this.elm.classList.add('faq-list-init');
        }
    }

    findIndexOfListContent(newListContentElm) {
        let index = 0;
        for (const elm of this.elm.getElementsByTagName('dd')) {
            if (elm === newListContentElm) {
                return index;
            }
            index++;
        }
        return -1;
    }

    onClickFaqHeading(e) {
        const elm = e.target;
        const nextElm = elm.nextElementSibling;
        const nextElmIndex = this.findIndexOfListContent(nextElm);

        this.hideFaqListContentItems(nextElmIndex);
    }

    addClickEventToHeadings() {
        for (const elm of this.elm.getElementsByTagName('dt')) {
            elm.addEventListener('click', this.onClickFaqHeading.bind(this));
        }
    }

    hideFaqListContentItems(excludeIndex) {
        excludeIndex = excludeIndex !== undefined ? excludeIndex : 0;

        const elements = this.elm.getElementsByTagName('dd');

        let index = 0;
        for (const elm of elements) {
            const hasClassHidden = elm.classList.contains('hidden');

            elm.classList.remove('hidden');
            if (excludeIndex !== index || !hasClassHidden) {
                elm.classList.add('hidden');
            }

            index++;
        }
    }
}